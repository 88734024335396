import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    CircularProgress,
    Box,
    IconButton,
} from '@mui/material'
import { get, isEmpty } from 'lodash-es'
import { JsonFormsWrapper } from './jsonFormComponents/JsonFormsWrapper'
import { HorizontalTextField } from './common'
import FileCopy from '@mui/icons-material/FileCopy'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const ConnectorInfoDialog = ({
    close,
    open,
    webhookUrl,
    callbackUri,
    webhookSecretKey,
    dialogLoading,
    connector,
    tenantStatusAuth,
}) => {
    const onJsonFormChange = () => {}
    const connectorInfo = get(connector, 'connectorInfo', {})
    const connectorAuthName =
        get(connector, 'metadata.override_name')?.toUpperCase() ||
        connector.name.toUpperCase()
    const isConnected =
        tenantStatusAuth &&
        get(tenantStatusAuth, `${connectorAuthName}_AUTH_STATUS`) ===
            'Connected'
    return (
        <Dialog
            open={open}
            maxWidth="sm"
            fullWidth={true}
            sx={{
                padding: '20px',
            }}
        >
            <DialogTitle
                sx={{
                    margin: 'auto',
                }}
            >
                {connector.label} Info
            </DialogTitle>

            <DialogContent
                sx={{
                    // Need this because jsonForms adds 125% width to field label... not sure why
                    width: '90%',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 'auto',
                }}
            >
                <div>
                    <HorizontalTextField
                        record={tenantStatusAuth}
                        rowName={
                            isConnected ? 'CONNECTION DATE' : 'DISCONNECT DATE'
                        }
                        source={`${connectorAuthName}_LAST_CHANGE`}
                    />
                    {connector?.type === 'oauth2' && (
                        <Box
                            sx={{
                                display: 'flex',
                                'div > p': { fontSize: '15px' },
                            }}
                        >
                            <CopyToClipboard text={callbackUri}>
                                <IconButton>
                                    <FileCopy />
                                </IconButton>
                            </CopyToClipboard>
                            <HorizontalTextField
                                record={{
                                    callback_uri: callbackUri,
                                }}
                                rowName={'CALLBACK URI'}
                                source={'callback_uri'}
                            />
                        </Box>
                    )}
                    {!isEmpty(connectorInfo) &&
                        (dialogLoading ? (
                            <CircularProgress
                                sx={{
                                    margin: 'auto',
                                }}
                            />
                        ) : (
                            <div>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <DialogTitle
                                        sx={{
                                            padding: '20px 0 0 0',
                                            display: 'flex',
                                        }}
                                    >
                                        Webhooks Info{' '}
                                    </DialogTitle>
                                </Box>
                                <JsonFormsWrapper
                                    schema={get(connectorInfo, 'schema')}
                                    uischema={get(connectorInfo, 'uischema')}
                                    data={{
                                        webhook_url: webhookUrl,
                                        webhook_signature_key: webhookSecretKey,
                                    }}
                                    onJsonFormChange={onJsonFormChange}
                                    fullWidth={true}
                                />
                            </div>
                        ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close()}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConnectorInfoDialog
