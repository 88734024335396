import React, { useEffect, useState } from 'react'
import { Edit, useRedirect, SelectInput } from 'react-admin'
import { useForm, FormProvider } from 'react-hook-form'
import { UpdateButton } from '../../../Wizard/WizardFormButtons'
import { Divider, Button } from '@mui/material'
import {
    PageTitle,
    SelectReleasesInput,
    ReleaseOptionSelector,
} from '../../../common'
import { CustomLabel } from '../../../CustomLabel'
import { isEmpty, get } from 'lodash-es'
import { JsonFormsWrapper } from '../../../jsonFormComponents/JsonFormsWrapper'
import { getDynamicConfigSchema } from '@thefront/pandipackV2'
import DeleteWithConfirmButton from '../../../react_admin/DeleteWithConfirmButton'

const TenantEditSettings = (props) => {
    const { tenant, integrationReleases, ...rest } = props
    const resource = 'tenants'
    const redirect = useRedirect()

    const [jsonFormValues, setJsonFormValues] = useState(
        get(tenant, 'configs', {})
    )
    const [isJsonFormValid, setIsJsonFormValid] = useState(false)
    const [schema, setSchema] = useState()
    const [uischema, setUiSchema] = useState({})

    // Call back to get state from child
    const onJsonFormChange = (errors, jsonFormValues) => {
        setJsonFormValues(jsonFormValues)
        isEmpty(errors) ? setIsJsonFormValid(true) : setIsJsonFormValid(false)
    }

    const [updatedTenant, setUpdatedTenant] = useState(tenant)
    const [releaseOption, setReleaseOption] = useState(
        tenant.integrationReleaseChannel ? 'channel' : 'release'
    )

    const methods = useForm({
        defaultValues: tenant,
    })

    useEffect(() => {
        if (
            !isEmpty(updatedTenant) &&
            !isEmpty(updatedTenant.integrationRelease)
        ) {
            try {
                setSchema(
                    getDynamicConfigSchema(
                        get(
                            updatedTenant,
                            'integrationRelease.config_schema.schema',
                            {}
                        ),
                        updatedTenant
                    )
                )
            } catch (err) {
                setSchema(err.toString())
                console.error(err)
            }
            setUiSchema(
                get(
                    updatedTenant,
                    'integrationRelease.config_schema.uischema',
                    {}
                )
            )
        }

        //reset form state to updated form
        methods.reset(updatedTenant)
    }, [updatedTenant, methods])

    return (
        <div>
            {tenant && (
                <PageTitle title={'Connection Settings: ' + tenant.name} />
            )}
            <Divider className="pageHead" />
            <div
                style={{
                    maxWidth: '800px',
                }}
            >
                <Edit {...rest} actions={null} component="div">
                    <>
                        <FormProvider {...methods}>
                            <>
                                <CustomLabel title="Version" />
                                <ReleaseOptionSelector
                                    releaseOption={releaseOption}
                                    setReleaseOption={setReleaseOption}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingTop: '10px',
                                    }}
                                >
                                    {releaseOption === 'channel' ? (
                                        <SelectInput
                                            source="integrationReleaseChannel"
                                            choices={['Latest', 'Default'].map(
                                                (value) => ({
                                                    name: value,
                                                    id: value,
                                                })
                                            )}
                                            variant="outlined"
                                            label="Integration Release Channel"
                                            emptyText="Select Release"
                                            sx={{
                                                width: '328px',
                                            }}
                                        />
                                    ) : (
                                        <SelectReleasesInput
                                            source="integrationRelease.id"
                                            integrationId={
                                                tenant?.integration.id
                                            }
                                            onChange={() =>
                                                methods.setValue(
                                                    'integrationReleaseChannel',
                                                    null
                                                )
                                            }
                                        />
                                    )}
                                    <div
                                        style={{
                                            marginBottom: '21px',
                                        }}
                                    >
                                        <UpdateButton
                                            disabled={
                                                // formState.isDirty was not working here,
                                                // possibly becase the radio button value was
                                                //triggering a false positive. It doesn't show
                                                // up in the list of dirty fields, however, so
                                                // we can look there.
                                                isEmpty(
                                                    methods.formState
                                                        .dirtyFields
                                                ) ||
                                                (releaseOption === 'channel' &&
                                                    !methods.getValues()
                                                        .integrationReleaseChannel)
                                            }
                                            values={methods.getValues()}
                                            resource={resource}
                                            jsonFormValues={jsonFormValues}
                                            record={updatedTenant}
                                            integrationReleases={
                                                integrationReleases['data']
                                            }
                                            redirectOnSuccess={false}
                                            text="UPDATE"
                                            setUpdatedTenant={setUpdatedTenant}
                                        />
                                    </div>
                                </div>
                            </>
                            <>
                                <CustomLabel title="Configuration" />
                                <div>
                                    {!isEmpty(schema) &&
                                    typeof schema !== 'string' ? (
                                        <JsonFormsWrapper
                                            onJsonFormChange={onJsonFormChange}
                                            schema={schema}
                                            tenant={updatedTenant}
                                            uischema={uischema}
                                            data={
                                                !isEmpty(jsonFormValues)
                                                    ? jsonFormValues
                                                    : get(
                                                          updatedTenant,
                                                          'configs',
                                                          {}
                                                      )
                                            }
                                            dynamicConfigs={
                                                updatedTenant?.status
                                                    ?.dynamicConfigs
                                            }
                                        />
                                    ) : (
                                        <div>
                                            {' '}
                                            This integration release has no
                                            configurations set up or there is an
                                            issue with your recent context.{' '}
                                            <br />
                                            {schema}
                                        </div>
                                    )}
                                </div>
                                <Divider
                                    sx={{
                                        margin: '30px 0',
                                    }}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div>
                                        <Button
                                            className={'clearButton'}
                                            onClick={() => {
                                                redirect(
                                                    '/' +
                                                        resource +
                                                        '/' +
                                                        updatedTenant.id +
                                                        '/show'
                                                )
                                            }}
                                        >
                                            {' '}
                                            CANCEL{' '}
                                        </Button>
                                        <UpdateButton
                                            disabled={!isJsonFormValid}
                                            values={methods.getValues()}
                                            resource={resource}
                                            jsonFormValues={jsonFormValues}
                                            record={updatedTenant}
                                            integrationReleases={
                                                integrationReleases['data']
                                            }
                                            text="SAVE CHANGES"
                                        />
                                    </div>
                                    <DeleteWithConfirmButton
                                        id={updatedTenant.id}
                                        resource="tenants"
                                        label="Archive Tenant"
                                        icon={null}
                                        confirmContent="Are you sure you want to archive this tenant?"
                                        toastContent="Tenant archived"
                                        confirmTitle={`Archive ${tenant.name}?`}
                                        variant="contained"
                                        color="error"
                                        sx={{
                                            width: '170px',
                                            height: '42px',
                                            margin: '0 5px 0 5px',
                                            color: 'white',
                                        }}
                                    />
                                </div>
                            </>
                        </FormProvider>
                    </>
                </Edit>
            </div>
        </div>
    )
}

export default TenantEditSettings
