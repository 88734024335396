import React, { useState, useEffect, Fragment, useMemo } from 'react'
import {
    Create,
    TextInput,
    ReferenceInput,
    SelectInput,
    TopToolbar,
    useGetList,
    useDataProvider,
} from 'react-admin'
import { LinearProgress } from '@mui/material'
import { connect } from 'react-redux'
import WizardForm from '../../Wizard/WizardForm'
import { isEmpty, get } from 'lodash-es'
import {
    CustomDivider,
    SelectReleasesInput,
    ChildrenWithProps,
    ReleaseOptionSelector,
} from '../../common'
import ConnectForm from '../../Auth/ConnectForm'
import {
    isLowerCase,
    alphaNumericDashUnderscorePeriod,
} from '../../inputValidators'
import { JsonFormsWrapper } from '../../jsonFormComponents/JsonFormsWrapper'
import CronInput from '../../CronInput'
import { CustomLabel } from '../../CustomLabel'
import PauseToggle from '../../PauseToggle'
import { API_MAX_PER_PAGE } from '../../../appConfigs'
import { useSearchParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'

const validateCreateTabForm = (values) => {
    const errors = {}
    if (!get(values, 'name')) {
        errors.name = 'Required'
    } else if (isLowerCase(values.name)) {
        errors.name = isLowerCase(values.name)
    } else if (alphaNumericDashUnderscorePeriod(values.name)) {
        errors.name = alphaNumericDashUnderscorePeriod(values.name)
    }

    if (!values.integration || values.integration === -1) {
        errors.integration = 'Required'
    }
    if (
        (!values.integrationRelease || values.integrationRelease === -1) &&
        (!values.integrationReleaseChannel ||
            values.integrationReleaseChannel === -1)
    ) {
        errors.integrationRelease = 'Required'
        errors.integrationReleaseChannel = 'Required'
    }

    return { values: values, errors: errors }
}

const CreateTab = (props) => {
    const {
        integrations,
        setSelectedIntegrationId,
        integrationFromURL,
        filteredReleases,
    } = props
    const [releaseOption, setReleaseOption] = useState('release')
    const { setValue, getValues } = useFormContext()

    useEffect(() => {
        const integration = integrations.find(
            (integration) => integration.id === integrationFromURL
        )
        if (integration) {
            setValue(
                'paused',
                JSON.parse(get(integration, 'marketplaceSettings.paused', true))
            )
            setValue(
                'userSchedule',
                get(integration, 'marketplaceSettings.schedule')
            )
            setSelectedIntegrationId(integrationFromURL)
            setValue('integration', integrationFromURL)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integrationFromURL, integrations])

    const integrationChange = (ev) => {
        const selectedIntegrationId = ev.target.value
        const selectedIntegration = integrations.find(
            (integration) => integration.id === setSelectedIntegrationId
        )
        setValue('integration', selectedIntegrationId)
        // On integration change, set tenant schedule based on integration default schedule
        setValue(
            'userSchedule',
            get(
                selectedIntegration,
                'marketplaceSettings.schedule',
                '0 1 * * *'
            )
        )

        setValue(
            'paused',
            JSON.parse(
                get(selectedIntegration, 'marketplaceSettings.paused', true)
            )
        )
        setValue('integrationRelease', -1)
        setValue('integrationReleaseChannel', -1)
        setSelectedIntegrationId(selectedIntegrationId)
    }

    return (
        <div>
            <CustomLabel title="Select Integration" />
            <ReferenceInput
                label="Select Integration"
                source="integration"
                reference="integrations"
                perPage={API_MAX_PER_PAGE}
            >
                <SelectInput
                    optionText={'name'}
                    onChange={integrationChange}
                    defaultValue={integrationFromURL}
                    variant="outlined"
                    emptyText={'Select Integration'}
                    emptyValue={-1}
                />
            </ReferenceInput>
            <CustomLabel title="Version" />
            <ReleaseOptionSelector
                releaseOption={releaseOption}
                setReleaseOption={setReleaseOption}
            />
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: '10px',
                }}
            >
                {releaseOption === 'channel' ? (
                    <SelectInput
                        source="integrationReleaseChannel"
                        choices={['Latest', 'Default'].map((value) => ({
                            name: value,
                            id: value,
                        }))}
                        variant="outlined"
                        label="Integration Release Channel"
                        emptyText="Select Release"
                        sx={{
                            width: '328px',
                        }}
                        disabled={isEmpty(filteredReleases)}
                    />
                ) : (
                    <SelectReleasesInput
                        source="integrationRelease"
                        values={getValues()}
                        onChange={() =>
                            setValue('integrationReleaseChannel', null)
                        }
                        disabled={isEmpty(filteredReleases)}
                    />
                )}
            </div>
            <CustomDivider
                sx={{
                    margin: '20px 0 0 0',
                }}
            />
            <CustomLabel title="NAME" />
            <TextInput
                label="NAME"
                source="name"
                helperText='First and last characters must be lowercase alphanumeric. Other characters may include: "-", "_", or "."'
            />
        </div>
    )
}
const TenantCreate = (props) => {
    const { theme } = props
    const [jsonFormValues, setJsonFormValues] = useState({})
    const [isJsonFormValid, setIsJsonFormValid] = useState(false)
    const [formErrorState, setFormErrorState] = useState(false)
    const [integrationReleases, setIntegrationReleases] = useState([])
    const [releasesLoading, setReleasesLoading] = useState(false)
    let selectedReleaseConfigs = useMemo(() => {}, [])
    const dataProvider = useDataProvider()

    let [searchParams] = useSearchParams()
    const [integrationFromURL, setIntegrationFromURL] = useState(undefined)
    const [selectedIntegrationId, setSelectedIntegrationId] = useState(null)

    useEffect(() => {
        if (searchParams.has('integration_id')) {
            setIntegrationFromURL(parseInt(searchParams.get('integration_id')))
            setSelectedIntegrationId(
                parseInt(searchParams.get('integration_id'))
            )
        }
    }, [searchParams])

    const { data: integrations, isLoading: integrationsLoading } = useGetList(
        'integrations',
        {
            pagination: { page: 1, perPage: 500 },
        }
    )

    const [recordData, setRecordData] = useState({
        userSchedule: '0 1 * * *',
        paused: true,
        integration: integrationFromURL || '',
    })
    const integrationScheduleList = get(
        recordData,
        'integration.sync_schedule_times',
        null
    )

    /**
     * This useEffect hook is similar to componentDidMount and componentDidUpdate.
     * When creating a tenant from IntegrationShow page, we pass the integration id as a URL search query.
     * If there is an integration id in the URL, set its releases for the selector component.
     */
    useEffect(() => {
        if (integrationFromURL) {
            setRecordData({ ...recordData, integration: integrationFromURL })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integrationFromURL])

    if (!isEmpty(recordData) && !releasesLoading) {
        selectedReleaseConfigs = integrationReleases?.find(
            (release) => release.id === recordData?.integrationRelease?.id
        )?.configSchema
    }

    // Form is valid if there are no configs from selected release. Let them pass through the config tab.
    useEffect(() => {
        if (isEmpty(selectedReleaseConfigs)) {
            setIsJsonFormValid(true)
        }
    }, [selectedReleaseConfigs])

    useEffect(() => {
        if (selectedIntegrationId !== null) {
            setReleasesLoading(true)
            dataProvider
                .getList('integrationreleases', {
                    pagination: { page: 1, perPage: 500 },
                    sort: { field: 'created_date', order: 'DESC' },
                    filter: {
                        integration_id: selectedIntegrationId,
                    },
                })
                .then(({ data }) => {
                    setReleasesLoading(false)
                    setIntegrationReleases(data)
                })
                .catch((error) => {
                    console.debug(error)
                })
        }
    }, [dataProvider, selectedIntegrationId])

    // Call back to get state from child
    const onJsonFormChange = (errors, values) => {
        setJsonFormValues(values)
        isEmpty(errors) ? setIsJsonFormValid(true) : setIsJsonFormValid(false)
    }

    if (integrationsLoading) {
        return <LinearProgress />
    }

    return (
        <>
            <TopToolbar>
                <h1>New Tenant</h1>
            </TopToolbar>

            <Create>
                <WizardForm
                    integrations={integrations}
                    jsonFormValues={jsonFormValues}
                    isJsonFormValid={isJsonFormValid}
                    recordData={recordData}
                    setRecordData={setRecordData}
                    integrationReleases={integrationReleases}
                    formErrorState={formErrorState}
                    resource={'tenants'}
                    validate={validateCreateTabForm}
                >
                    <CreateTab
                        label="1. Create"
                        integrations={integrations}
                        integrationFromURL={integrationFromURL}
                        setSelectedIntegrationId={setSelectedIntegrationId}
                        filteredReleases={integrationReleases}
                    />
                    <div label="2. Connect">
                        <CustomLabel
                            title="Tenant Secrets"
                            subtitle="Click to launch and follow authorization instructions"
                        />
                        <ConnectForm tenant={recordData} pollTenant={true} />
                    </div>
                    <div label="3. Configure">
                        {!isEmpty(selectedReleaseConfigs) ? (
                            <JsonFormsWrapper
                                onJsonFormChange={onJsonFormChange}
                                schema={selectedReleaseConfigs.schema}
                                uischema={selectedReleaseConfigs.uischema}
                                data={jsonFormValues}
                            />
                        ) : (
                            <div>
                                {' '}
                                This integration release has no configurations
                                set up!
                            </div>
                        )}
                    </div>
                    <ChildrenWithProps label="4. Schedule">
                        <CronInput
                            source="userSchedule"
                            pauseInDropdown={
                                theme?.configs.marketplaceSettings
                                    ?.jointCronSelector
                            }
                            integrationScheduleList={integrationScheduleList}
                            setFormErrorState={setFormErrorState}
                            formErrorState={formErrorState}
                        />
                        {get(
                            theme,
                            'configs.marketplaceSettings.jointCronSelector',
                            false
                        ) ? (
                            <Fragment />
                        ) : (
                            <PauseToggle
                                source="paused"
                                label="Pause Sync Schedule"
                                helpText="Disable to resume sync schedule"
                            />
                        )}
                    </ChildrenWithProps>
                </WizardForm>
            </Create>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        theme: state.org.theme,
    }
}

export default connect(mapStateToProps)(TenantCreate)
