import React, { useState } from 'react'
import {
    Button,
    Box,
    Autocomplete,
    TextField,
    Checkbox,
    InputLabel,
    FormGroup,
    FormHelperText,
    Grid,
    LinearProgress,
    InputAdornment,
    ListItem,
} from '@mui/material'
import { Add, Remove } from '@mui/icons-material'
import { isEmpty as lodashIsEmpty, get } from 'lodash-es'
import { Capitalize } from '@thefront/pandipackV2'
import { useConnectorsList } from './common'
import { useFieldArray, useFormContext } from 'react-hook-form'

const styles = {
    buttonStyle: {
        marginLeft: '300px',
        display: 'inline-block',
        borderRadius: '20px',
        height: '36px',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        color: 'black',
    },
    listStyle: {
        display: 'flex',
        alignItems: 'center',
        margin: '10px 0 10px 0',
    },
    addButtonStyle: {
        width: '33%',
        color: 'black',
        boxShadow: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
    selectorImageStyle: {
        height: '40px',
        width: '40px',
        padding: '0 10px',
        objectContain: 'cover',
    },
    selectedImageStyle: {
        height: '40px',
        width: '40px',
        margin: '0 20px 20px 20px',
    },
}

// ---------------------- Validators ---------------------- //
// const isDuplicate = (value, allValues) => {
//     const counts = countBy(allValues.connectors, 'name');
//     return filter(allValues.connectors, x => counts[x.name] > 1).length > 1 ? "Duplicate Connector" : ''
// };
//
// const isEmpty = (value, allValues) => {
//     return allValues.connectors && allValues.connectors.length >= 1 && allValues.connectors[allValues.connectors.length - 1].name === "" ? "Please Select A Connector" : ''
// };

// -------------------- Validators End -------------------- //
const RenderSelectField = ({
    values,
    label,
    classes,
    connectorList,
    setValue,
    index,
    ...custom
}) => {
    const { trigger } = useFormContext()
    // Must have at least 1 connector
    const validateSelector = (value) => {
        if (lodashIsEmpty(value)) {
            return 'Required'
        } else if (value.some((connector) => connector?.name === '')) {
            return 'Select Connector!'
        } else return null
    }
    const [selectedValue, setSelectedValue] = useState({})
    return (
        <Grid
            container
            style={{
                width: '33%',
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <Grid item xs={2}>
                <FormGroup>
                    <Grid item container direction="row">
                        <Checkbox
                            style={{ marginTop: '25px' }}
                            {...custom}
                            key={`checkbox-${index}`}
                            value={
                                values[index]?.isGlobal
                                    ? values[index].isGlobal.toString()
                                    : 'false'
                            }
                            checked={
                                values[index]?.isGlobal
                                    ? values[index]?.isGlobal
                                    : false
                            }
                            onChange={(event) => {
                                const connectorObj = {
                                    ...values[index],
                                    isGlobal: event.target.checked,
                                }
                                values[index] = connectorObj
                                setValue('connectors', values)
                            }}
                        />
                    </Grid>
                </FormGroup>
            </Grid>
            <Grid item xs={8}>
                <FormGroup required style={{ width: '100%' }}>
                    <Autocomplete
                        disableClearable
                        options={Object.values(connectorList)}
                        getOptionLabel={(option) =>
                            option.label ? option.label : option.name
                        }
                        style={{ width: 400, margin: '25px 25px 0 25px' }}
                        onChange={(event, newValue) => {
                            const connectorObj = {
                                ...values[index],
                                name: newValue.name,
                                overrideName: get(
                                    newValue,
                                    'metadata.override_name',
                                    undefined
                                ),
                            }
                            setSelectedValue(newValue)
                            values[index] = connectorObj
                            setValue('connectors', values)
                            trigger()
                        }}
                        renderOption={(props, option) => (
                            <ListItem
                                {...props}
                                sx={{
                                    '&.MuiListItem-root:hover': {
                                        backgroundColor: '#D3D3D3',
                                    },
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                    }}
                                >
                                    <img
                                        src={option.logoUrl}
                                        alt="option logo"
                                        style={styles.selectorImageStyle}
                                    />
                                    <div
                                        style={{
                                            maxWidth: '300px',
                                            textAlign: 'left',
                                        }}
                                    >
                                        {option.label
                                            ? Capitalize(option.label)
                                            : Capitalize(option.name)}
                                    </div>
                                </div>
                            </ListItem>
                        )}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    sx={{
                                        '.MuiAutocomplete-input': {
                                            marginBottom: '15px',
                                            fontWeight: 'bold',
                                        },
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        height: '100px',
                                                    }}
                                                >
                                                    {!lodashIsEmpty(
                                                        selectedValue
                                                    ) && (
                                                        <img
                                                            src={
                                                                selectedValue?.logoUrl
                                                            }
                                                            alt="option logo"
                                                            style={
                                                                styles.selectedImageStyle
                                                            }
                                                        />
                                                    )}
                                                </Box>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )
                        }}
                    />
                </FormGroup>
            </Grid>
            <Grid item xs={2}>
                {validateSelector([values[index]]) && (
                    <FormHelperText
                        style={{
                            padding: '12px',
                            marginLeft: '240px',
                            marginTop: '15px',
                        }}
                    >
                        {'required'}
                    </FormHelperText>
                )}
            </Grid>
        </Grid>
    )
}

const SelectorConnectorForm = () => {
    const { getValues, setValue } = useFormContext()
    const formValues = getValues()

    const { data: connectorList, isLoading } = useConnectorsList()
    const { fields, append, remove } = useFieldArray({
        name: 'connectors',
    })
    return isLoading ? (
        <LinearProgress style={{ width: '50%' }} />
    ) : (
        <div>
            <InputLabel style={{ marginLeft: '40px', fontWeight: 'bold' }}>
                Global
            </InputLabel>
            <ul>
                {fields.map((field, index) => {
                    return (
                        <li key={`listItem-${index}`} style={styles.listStyle}>
                            <RenderSelectField
                                values={formValues.connectors}
                                setValue={setValue}
                                index={index}
                                connectorList={connectorList}
                            />
                            {index > 0 && (
                                <Button
                                    onClick={() => remove(index)}
                                    disabled={fields.length === 1}
                                    style={styles.buttonStyle}
                                    variant="contained"
                                >
                                    <Remove />
                                </Button>
                            )}
                        </li>
                    )
                })}
                <Button
                    onClick={() =>
                        append({
                            name: '',
                            isGlobal: false,
                        })
                    }
                    style={styles.addButtonStyle}
                    disabled={fields.length === 4}
                    variant="contained"
                >
                    <Add />
                </Button>
            </ul>
        </div>
    )
}

export default SelectorConnectorForm
