import React, { useEffect, useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
import { isEmpty, get } from 'lodash-es'
import ConnectDialog from './ConnectDialog'
import { isConnectorConnected, Capitalize } from '@thefront/pandipackV2'
import { DisconnectDialog, useConnectors } from '../common'
import { useDataProvider, useNotify } from 'react-admin'
// TODO this is custom for kombo..
import { showKomboConnect } from '@kombo-api/connect'

const connectFormStyles = {
    innerFormStyle: {
        height: '66px',
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        padding: '7px',
        margin: '0px 5px 30px 5px',
        boxShadow: '0 0 0 1px #b8bbc185, 0 0 0 2px #dbdee5c7',
        borderRadius: '3px',
    },
    imgStyle: {
        height: '50px',
        width: '50px',
        margin: '0 auto',
        display: 'block',
    },
    imgContainerStyle: {
        flex: '1 2 10%',
        margin: 'auto',
    },
    statusStyle: {
        objectFit: 'contain',
        width: '9%',
        verticalAlign: 'middle',
        padding: '0 0 0 0',
    },
    buttonContainer: {
        flex: '1 1 5%',
    },
    buttonStyle: {
        margin: '5px',
        backgroundColor: '#FFF',
    },
    filledButton: {
        color: 'white',
        width: '120px',
        height: '40px',
        backgroundColor: '#626FFC',
        fontSize: '16px',
        borderRadius: '0',
        margin: '0 5px 0 5px',
        '&:disabled': {
            background: 'transparent',
        },
        boxShadow: 'none',
    },
    connectorTitleStyle: {
        flex: '2 1 55%',
        textAlign: 'left',
    },
    circularProgressStyle: {
        flex: '1 1 75%',
        padding: '7px 0 0 7px',
        marginLeft: '17em',
    },
}

export const ConnectForm = (props) => {
    const { tenant, connector, pollTenant, autoRedirect = false } = props
    const dataProvider = useDataProvider()
    const notify = useNotify()

    const [openConnectDialog, setOpenConnectDialog] = useState(false)
    const [openDisconnectDialog, setOpenDisconnectDialog] = useState(false)
    const [connect, setConnect] = useState(true)

    //state for connector dialogues
    const [modalClose, setModalClose] = useState(true)
    const [oAuth2Link, setOauth2Link] = useState('')
    const [modalError, setModalError] = useState('')
    const [modalLoading, setModalLoading] = useState(false)

    //poll tenant on this form so we can update connected status
    const [updatedTenant, setTenant] = useState(tenant)

    // TODO this is custom for kombo..
    const [komboToken, setKomboToken] = useState('')

    useEffect(() => {
        if (!isEmpty(komboToken)) {
            connectBasic('kombo', { token: komboToken })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [komboToken])

    const tenantCallback = (event) => {
        setTenant(event.payload)
    }

    useEffect(() => {
        if (pollTenant) {
            dataProvider.subscribe(`tenants/${tenant.id}`, tenantCallback)
            return () =>
                dataProvider.unsubscribe(`tenants/${tenant.id}`, tenantCallback)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pollTenant])

    useEffect(() => {
        if (!pollTenant && !isEmpty(tenant)) {
            setTenant(tenant)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pollTenant, tenant])

    const connectBasicSuccess = () => {
        setModalError('')
        setModalLoading(false)
        console.debug('CONNECT BASIC SUCCESS:', connector.name)
        notify('Save Success!')
        setModalClose(true)
        setOpenConnectDialog(false)
    }

    const connectBasicFailure = (error) => {
        setModalError(error)
        notify(error, { type: 'error' })
        setModalLoading(false)
        setModalClose(false)
    }

    const connectBasicLoading = () => {
        setModalError('')
        setModalLoading(true)
        setModalClose(false)
    }

    const resetModalState = () => {
        setModalError('')
        setModalLoading(false)
        setModalClose(true)
    }

    const connectRequest = (connectorName, form_data = undefined) => {
        setModalError('')
        dataProvider
            .AUTHOR('author/connect_tenant', {
                data: {
                    connector_name: connectorName,
                    integration_name: updatedTenant.integration.name,
                    tenant_id: updatedTenant.id,
                    form_data,
                },
            })
            .then(async (res) => {
                const link = res.data.link || res.data.installUrl // install URL is for the custom linnworks login flow
                // TODO this is custom for kombo..
                if (connectorName === 'kombo') {
                    setKomboToken(await showKomboConnect(link))
                } else if (autoRedirect) {
                    window
                        .open(link, '_blank', 'popup,width=600,height=650')
                        .focus()
                } else {
                    setOauth2Link(link)
                    setOpenConnectDialog(true)
                }
            })
            .catch((error) => {
                notify('Error creating OAuth2 Link, please check credentials.')
                setModalError(
                    'Error creating OAuth2 Link, please check credentials.'
                )
                console.debug(error)
            })
    }

    const connectBasic = (connectorName, values) => {
        connectBasicLoading()
        dataProvider
            .AUTHOR('author/callback/basic', {
                data: {
                    connector_name: connectorName,
                    integration_name: updatedTenant.integration.name,
                    tenant_id: updatedTenant.id,
                    secrets: values,
                },
            })
            .then((res) => {
                if (
                    res.data?.statusCode &&
                    (res.data.statusCode < 200 || res.data.statusCode >= 300)
                )
                    connectBasicFailure(res.data.message)
                else connectBasicSuccess()
            })
            .catch((err) => {
                console.debug(err)
                connectBasicFailure(err)
            })
    }

    const disconnectRequest = (connectorName) => {
        dataProvider
            .AUTHOR('author/disconnect_tenant', {
                data: {
                    connector_name: connectorName,
                    integration_name: updatedTenant.integration.name,
                    tenant_id: updatedTenant.id,
                },
            })
            .then(() => {
                console.debug('Disconnect SUCCESS: ', connector.name)
                notify('Disconnect Success!')
            })
    }

    // Redux will show modalClose as true when a successful connection is created. We must update our local state here.
    useEffect(() => {
        if (modalClose) {
            handleCloseConnectDialog()
        }
    }, [modalClose])

    // if anytime the connection dialog close, we must clear the redux modal state.
    useEffect(() => {
        if (!openConnectDialog) {
            resetModalState()
        }
    }, [openConnectDialog])

    // Redirect to oauth2 link if applicable, else render the connector form
    const handleOpenConnectDialog = () => {
        if (
            connector.type !== 'basic' &&
            get(connector, 'metadata.multi_step') !== true &&
            ['web', 'hybrid', 'custom'].includes(
                get(connector, 'metadata.grant_flow')
            )
        ) {
            connectRequest(connector.name)
        } else {
            setOpenConnectDialog(true)
        }
        if (get(connector, 'metadata.grant_flow') === 'custom') {
            setOpenConnectDialog(true)
        }
    }

    const handleCloseConnectDialog = () => {
        setModalError('')
        setOpenConnectDialog(false)
    }

    const handleOpenDisconnectDialog = () => {
        setOpenDisconnectDialog(true)
    }

    const handleCloseDisconnectDialog = () => {
        setOpenDisconnectDialog(false)
    }

    const disconnect = () => {
        disconnectRequest(connector.name)
        setOpenConnectDialog(false)
        setConnect(true)
    }

    const connectorLabel = connector.label || connector.id
    const isConnected = isConnectorConnected(updatedTenant, connector.name)

    return (
        <div>
            <div>
                <div style={connectFormStyles.innerFormStyle}>
                    <div style={connectFormStyles.imgContainerStyle}>
                        <img
                            src={connector.logoUrl}
                            alt=""
                            style={connectFormStyles.imgStyle}
                        />
                    </div>
                    <div style={connectFormStyles.connectorTitleStyle}>
                        {Capitalize(connectorLabel)}
                    </div>
                    <div style={connectFormStyles.buttonContainer}>
                        {connect && !isConnected ? (
                            <Button
                                children="Connect"
                                sx={connectFormStyles.filledButton}
                                onClick={handleOpenConnectDialog}
                                variant="contained"
                            />
                        ) : (
                            <Button
                                children="Disconnect"
                                sx={connectFormStyles.filledButton}
                                onClick={handleOpenDisconnectDialog}
                                variant="contained"
                            />
                        )}
                    </div>
                </div>
            </div>
            <ConnectDialog
                close={handleCloseConnectDialog}
                open={openConnectDialog}
                connectorName={connector.name}
                connector={connector}
                tenant={updatedTenant}
                oAuth2Link={oAuth2Link}
                modalError={modalError}
                modalLoading={modalLoading}
                connectRequest={connectRequest}
                connectBasic={connectBasic}
                autoRedirect={autoRedirect}
            />
            <DisconnectDialog
                handleClose={handleCloseDisconnectDialog}
                open={openDisconnectDialog}
                connector={connector}
                disconnect={disconnect}
            />
        </div>
    )
}

const connectFormWrapperStyles = {
    formStyle: {
        display: 'inline-block',
        width: '550px',
    },
}

/**
 * Form that renders connect forms/dialogs
 *
 * @param props -- only requires the Tenant. It will extract the connectors, ids, integrationName, etc from it.
 * @returns {*}
 * @constructor
 */
export default (props) => {
    const { tenant, pollTenant, autoRedirect = false } = props
    const connectors = useConnectors(tenant)

    return (
        <div style={connectFormWrapperStyles.formStyle}>
            {!isEmpty(connectors) ? (
                get(tenant, 'integration.connectors', []).map(
                    (tenantConnector) => {
                        if (!tenantConnector.isGlobal) {
                            return (
                                <ConnectForm
                                    connector={connectors.find(
                                        ({ name }) =>
                                            name === tenantConnector.name
                                    )}
                                    key={tenantConnector.name}
                                    tenant={tenant}
                                    pollTenant={pollTenant}
                                    autoRedirect={autoRedirect}
                                />
                            )
                        } else {
                            return null
                        }
                    }
                )
            ) : (
                <CircularProgress />
            )}
        </div>
    )
}
