import React, { useState } from 'react'
import { Divider, Button, Typography, LinearProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { JsonForms } from '@jsonforms/react'
import { materialRenderers } from '@jsonforms/material-renderers'
import { connect } from 'react-redux'
import { useUpdate, useGetOne, Form, useRedirect, useNotify } from 'react-admin'
import { updateOrgAction } from '../customActions'
import BooleanWithLink from './jsonFormComponents/BooleanWithLink'
import BooleanWithLinkTester from './jsonFormComponents/BooleanWithLinkTester'
import {
    CustomStringInput,
    CustomStringInputTester,
} from './jsonFormComponents/CustomStringInput'

const usePartnerIntakeStyles = makeStyles({
    outerContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
    },
    topLogo: {
        height: '35px',
    },
    formContainer: {
        alignSelf: 'center',
        width: '725px',
        backgroundColor: 'white',
        padding: '40px 0px 20px 0px',
        marginTop: '140px',
        marginBottom: '500px',
        borderRadius: '6px',
        minHeight: '600px',
        '& hr': {
            margin: '20px 0px 20px 0px',
        },
        '& button': {
            width: '163px',
        },
        '& h4': {
            fontWeight: 'bold',
        },
    },
    bigMargins: {
        marginLeft: '71px',
        marginRight: '74px',
    },
})

const PartnerIntake = ({
    topLogo,
    intakeForm,
    parentName,
    orgId,
    updateOrg,
}) => {
    const classes = usePartnerIntakeStyles()
    const [update] = useUpdate()
    const { data } = useGetOne('organizations', { id: orgId })
    const [answers, setAnswers] = useState({})
    const [errors, setErrors] = useState([])
    const notify = useNotify()
    const redirect = useRedirect()

    if (!orgId || !parentName || !intakeForm || !topLogo) {
        return <LinearProgress />
    }

    const { schema, uischema } = intakeForm

    const onClick = () => {
        updateOrg({ approvalStatus: 'Approved' })
        update(
            'organizations',
            {
                id: orgId,
                data: {
                    ...data,
                    partnerIntakeForm: {
                        schema: schema,
                        uischema: uischema,
                        answers: answers,
                    },
                    approvalStatus: 'Approved',
                },
                previousData: data,
            },
            {
                onSuccess: () => {
                    updateOrg({ approvalStatus: 'Approved' })
                    notify('Organization Approved!')
                    redirect('/integrations')
                    window.location.reload() //need a hard reload in order to get out of the intake form view
                },
                onError: (error) => {
                    console.debug(error)
                    updateOrg({ approvalStatus: 'Pending' })
                },
            }
        )
    }

    return (
        <div className={classes.outerContainer}>
            <span className={classes.topLogo}>
                <img src={topLogo} alt={parentName} height="35px" />
            </span>

            <div className={classes.formContainer}>
                <div className={classes.bigMargins}>
                    <Typography variant="h4">Partner Details</Typography>
                    <Typography>
                        Please fill out the following information
                    </Typography>
                </div>
                <Divider />

                <Form>
                    <div className={classes.bigMargins}>
                        <JsonForms
                            data={answers}
                            schema={schema}
                            uischema={uischema}
                            renderers={[
                                ...materialRenderers,
                                {
                                    tester: CustomStringInputTester,
                                    renderer: CustomStringInput,
                                },
                                {
                                    tester: BooleanWithLinkTester,
                                    renderer: BooleanWithLink,
                                },
                            ]}
                            onChange={({ errors, data }) => {
                                setAnswers(data)
                                setErrors(errors)
                            }}
                            validationMode="ValidateAndHide"
                        />
                        <Divider />
                        <Button
                            disabled={!!errors.length}
                            className="filledButton"
                            onClick={onClick}
                        >
                            Submit
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        topLogo: state.org.topLogo,
        intakeForm: state.org.intakeForm,
        parentName: state.org.parentName,
        orgId: state.org.orgId,
    }
}
const mapDispatchToProps = (dispatch) => ({
    updateOrg: (org) => dispatch(updateOrgAction(org)),
})
export default connect(mapStateToProps, mapDispatchToProps)(PartnerIntake)
